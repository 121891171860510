import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "internationalization-i18n"
    }}>{`Internationalization (i18n)`}</h1>
    <p>{`TODO`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`React-Intl`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`How to extract messages`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Polyfills Lazy-Loading`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Translations Lazy-Loading`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`How to add a translation file (+ needs a restart)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`How to change active locale (hooks & context)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`automatic locale selection.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`defining new locales.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`being notified of a locale change.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`selecting the preferred locale (guessPreferredLocale() hook)`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      